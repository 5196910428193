<template>
<div class="content-template">
  <div class="card animate__animated animate__fadeIn duration" id="card1">
      <div class="card_box_one">
        <div class="card_box_title2">
          <p class="font-blod">主力资金</p>
          <p>日内追踪</p>
          <p>{{dataTime}}</p>
        </div>
        <div class="width-100 height-100">
          <video
            src="video/video2.mp4"
            muted
            width="100%"
            height="100%"
            ref="video1"
          ></video>
        </div>
        <div class="logo-box logo-box3">
          <img src="../../assets/img/logo2.png" alt="" />
        </div>
      </div>
    </div>
  <div class="container container2">
    <div class="audio-box">
      <audio src="audio/EveryoneIsSoAlive.mp3" ref='bgAudio'></audio>
    </div>
    
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card2"
    >
      <div class="card_two_box">
        <div class="card_header">
          <span class="font-blod">主力资金净流入个股一览</span>
          <span>{{dataTime}}</span>
        </div>
        <div style="padding: 0px 36px">
          <div class="flex flex-between margin-t-10">
            <p>时间：<span>{{timeChange}}</span></p>
            <p>(亿元)</p>
          </div>
          <div id="chart1" style="width: 100%; height: 900px"></div>
        </div>
        <div class="logo-box ">
          <img src="../../assets/img/logo2.png" alt="" />
        </div>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card3"
    >
      <div class="card_three_box">
        <div class="card_header">
          <span class="font-blod">主力资金净流出个股一览</span>
          <span>{{dataTime}}</span>
        </div>
        <div style="padding: 0px 36px">
          <div class="flex flex-between margin-t-10">
            <p>时间：<span>{{timeChangeOut}}</span></p>
            <p>(亿元)</p>
          </div>
          <div id="chart2" style="width: 100%; height: 900px"></div>
        </div>
      </div>
      <div class="logo-box">
        <img src="../../assets/img/logo2.png" alt="" />
      </div>
    </div>
  </div>
  <div class="card animate__animated animate__fadeIn duration none" id="card4">
    <div class="width-100 height-100">
      <video
        class="end_video"
        src="video/end.mp4"
        muted
        width="100%"
        height="100%"
        ref="endVideo"
      ></video>
    </div>
  </div>
</div>
</template>

<script>
import $ from "jquery";
import { GetParams2 } from '@/utils/common'
import ApiServe from '@/api/index'
export default {
  name: "Template2",
  data() {
    return {
      // 主力资金净流入
      dataTime:'',
      timeChange:'9:30',
      timeChangeOut:'9:30',
      chart_data_1:[],
      xdata1: [],
      ydata1: [],
      chart_data_2:[],
      // 主力资金净流出
      xdata2: [],
      ydata2: [],

      // 龙虎榜营业部净买入
      xdata3: [],
      ydata3: [],
      // 龙虎榜机构净买入
      xdata4: [],
      ydata4: [],
      videoTotalTime:34,
      id:'',
      model_id:null,
      model_name:"",
      model_category:'',
      url_extra_params:null,
      ip:'',
      port:'',
      offset_x:'',
      offset_y:'',
      width:"",
      height:'',
      source:''
    };
  },
  created() {
    this.id = GetParams2().id || null
    this.model_id = GetParams2().model_id || 2
    this.model_name = GetParams2().model_name || null
    this.model_category = GetParams2().model_category || null
    this.url_extra_params = GetParams2().url_extra_params || null
    this.ip = GetParams2().ip || null
    this.port = GetParams2().port || null
    this.offset_x = GetParams2().offset_x || null
    this.offset_y = GetParams2().offset_y || null
    this.width = GetParams2().width || null
    this.height = GetParams2().height || null
    this.source = GetParams2().source || null
    this.getData();
    // 模板2没有音频
    // this.getAudio()
  },
  computed:{
    allReady(){
      const { chart_data_1, audio1Src } = this;
      return {
        chart_data_1,
        audio1Src,
      };
    },
    newIpPort(){
      if(this.source == 'ali'){
        return 'https://recordvideoali.wedengta.com'
      }else{
        return 'https://recordvideo.wedengta.com'
      }
    }
  },
  watch:{
    allReady(val){
      if(val.chart_data_1.length>0){
        if (this.ip && this.port) {
          const params = {
            ip:this.ip,
            port:this.port,
            offset_x:this.offset_x,
            offset_y:this.offset_y,
            width:this.width,
            height:this.height
          }
          ApiServe.recordstartPc(this.newIpPort,params).then(({code}) => {
            
          }).catch((err)=>{
            ApiServe.recordreleasePc(this.newIpPort,{ip:this.ip,port:this.port})
          });
          this.load();
        } else {
          alert('没获取到ip,port')
          this.load();
        }
      }
    }
  },
  methods: {
    getData() {
      let params = {
        model_id: this.model_id
      }
      if(this.url_extra_params){
        params = {
          model_id: this.model_id,
          url_extra_params:this.url_extra_params
        }
      }
      ApiServe.getDataPC(params).then(({data,code})=>{
          if(code == 200){
            this.dataTime = data.extra_param_1[0]
            this.chart_data_1 = data.chart_data_1
            this.xdata1 = data.chart_data_1[0].data.x || []
            this.ydata1 = (data.chart_data_1[0].data.y || []).map((n)=>{
              return Math.floor((n /100000000) * 100) / 100
            })
            this.chart_data_2 = data.chart_data_2
            this.xdata2 = data.chart_data_2[0].data.x || []
            this.ydata2 = (data.chart_data_2[0].data.y || []).map((n)=>{
              return Math.floor((n /100000000) * 100) / 100
            })
            if (this.ip && this.chart_data_1.length==0) {
              ApiServe.releasepcportPc({ ip: this.ip,port:this.port });
            }
          }  
      }).catch((err)=>{
          if (this.ip) {
            ApiServe.releasepcportPc({ ip: this.ip,port:this.port });
          }
        })
    },
    // 获取旁白
    getAudio(){
      // let params = {
      //   model_id: this.model_id
      // }
      // if(this.url_extra_params){
      //   params = {
      //     model_id: this.model_id,
      //     url_extra_params:this.url_extra_params
      //   }
      // }
      // ApiServe.getVideoData(params).then(({data,code})=>{
      //   if(code == 200){
          
   
      //   }
      // }).catch((err)=>{
      //     if (this.ip) {
      //       ApiServe.releasepcportPc({ ip: this.ip,port:this.port });
      //     }
      //   });
    },
    // 主力资金净流入个股一览
    loadChart1() {
      // 基于准备好的dom，初始化this.$echarts实例
      var myChart = this.$echarts.init(document.getElementById("chart1"));
      // 指定图表的配置项和数据
      var option = {
        grid: {
          left: "120px",
          top: "20px",
          bottom: "92px",
          right: "0",
        },
        animationDuration: 2000,
        xAxis: [
          {
            type: "value",
            show:false,
          },
        ],
         yAxis: [
          {
            type: "category",
            data: this.xdata1,
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: false },
            axisLabel:{
              color:'#AFF7FF',
              fontSize:24
            }
          },
        ],
        series: [
          {
            type: "bar",
            data: this.ydata1,
            barWidth: 40,
            barMinHeight:100,
            itemStyle: {
              normal: {
                label: {
                  show: true, //开启显示
                  position: "right", //在上方显示
                  textStyle: {
                    //数值样式
                    color: "#AFF7FF",
                    fontSize: 28,
                  },
                  offset:[-80,0],
                  formatter:function(value){
                    return Math.abs(value.value).toFixed(2)
                  }
                },
                color: "#E54444",
              },
            },
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },

    // 主力资金净流出个股一览
    loadChart2() {
      // 基于准备好的dom，初始化this.$echarts实例
      var myChart = this.$echarts.init(document.getElementById("chart2"));
      // 指定图表的配置项和数据
      var option = {
        grid: {
          left: "120px",
          top: "20px",
          bottom: "72px",
          right: "0",
        },
        animationDuration: 2000,
        xAxis: [
          {
            type: "value",
            show:false,
          },
        ],
        yAxis: [
          {
            type: "category",
            data: this.xdata2,
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: false },
            axisLabel:{
              color:'#AFF7FF',
              fontSize:24
            }
          },
        ],
        series: [
          {
            type: "bar",
            data: this.ydata2,
            barWidth: 40,
            barMinHeight:100,
            itemStyle: {
              normal: {
                label: {
                  show: true, //开启显示
                  position: "right", //在上方显示
                  textStyle: {
                    //数值样式
                    color: "#ffffff",
                    fontSize: 28,
                  },
                  offset:[-80,0],
                  formatter: function (value) {
                    return (-value.value).toFixed(2);
                  },
                },
                color: "#00B466",
              },
            },
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    endRecord(){
      let audiosOrderList =  []
      const data = {
        'id':this.id, 
        'model_id':this.model_id,
        'videoTotalTime':this.videoTotalTime,
        'videmBgm':"https://idtcdn.oss-cn-hangzhou.aliyuncs.com/Cube/VideoBgm/bg.mp3",
        'audiosOrderList':audiosOrderList
      }
      ApiServe.recordstopPc(this.newIpPort,{ip:this.ip,port:this.port}).then(()=>{
        ApiServe.combineaudioswithbgm(this.newIpPort,this.ip,this.port,data).then(()=>{
           ApiServe.recordreleasePc(this.newIpPort,{ip:this.ip,port:this.port})
        }).catch(()=>{
          ApiServe.recordreleasePc(this.newIpPort,{ip:this.ip,port:this.port})
        })
      }).catch(()=>{
        ApiServe.recordreleasePc(this.newIpPort,{ip:this.ip,port:this.port})
      })
    },
    load() {
      this.$refs.video1.play()  //首页的视频播放
      setTimeout( ()=> {
        $("#card1").addClass("animate__fadeOut");
      }, 2000);
      setTimeout(() => {
        $("#card1").addClass("none");
        $("#card2").removeClass("none");
        this.loadChart1();
      }, 3000);
      setTimeout(()=>{
        this.timeChange = '10:00'
        this.xdata1 = this.chart_data_1[1].data.x || []
        this.ydata1 = (this.chart_data_1[1].data.y || []).map((n)=>{
          return Math.floor((n /100000000) * 100) / 100
        })
        this.loadChart1();
      },5000)
         setTimeout(()=>{
        this.timeChange = '11:00'
        this.xdata1 = this.chart_data_1[2].data.x || []
        this.ydata1 = (this.chart_data_1[2].data.y || []).map((n)=>{
          return Math.floor((n /100000000) * 100) / 100
        })
        this.loadChart1();
      },7000)
      setTimeout(()=>{
        this.timeChange = '11:30'
        this.xdata1 = this.chart_data_1[3].data.x || []
        this.ydata1 = (this.chart_data_1[3].data.y || []).map((n)=>{
          return Math.floor((n /100000000) * 100) / 100
        })
        this.loadChart1();
      },9000)
       setTimeout(()=>{
        this.timeChange = '14:00'
        this.xdata1 = this.chart_data_1[4].data.x || []
        this.ydata1 = (this.chart_data_1[4].data.y || []).map((n)=>{
          return Math.floor((n /100000000) * 100) / 100
        })
        this.loadChart1();
      },11000)
      setTimeout(()=>{
        this.timeChange = '15:00'
        this.xdata1 = this.chart_data_1[5].data.x || []
        this.ydata1 = (this.chart_data_1[5].data.y || []).map((n)=>{
          return Math.floor((n /100000000) * 100) / 100
        })
        this.loadChart1();
      },13000)
      setTimeout( ()=> {
        $("#card2").addClass("animate__fadeOut");
      }, 15000);
      setTimeout( ()=> {
        $("#card2").addClass("none");
        $("#card3").removeClass("none");
        this.loadChart2();
      }, 16000);
      setTimeout( ()=> {
        this.timeChangeOut = '10:00'
        this.xdata2 = this.chart_data_2[1].data.x || []
        this.ydata2 = (this.chart_data_2[1].data.y || []).map((n)=>{
          return Math.floor((n /100000000) * 100) / 100
        })
        this.loadChart2();
      }, 18000);
      setTimeout( ()=> {
        this.timeChangeOut = '11:00'
        this.xdata2 = this.chart_data_2[2].data.x || []
        this.ydata2 = (this.chart_data_2[2].data.y || []).map((n)=>{
          return Math.floor((n /100000000) * 100) / 100
        })
        this.loadChart2();
      }, 20000);
      setTimeout( ()=> {
        this.timeChangeOut = '11:30'
        this.xdata2 = this.chart_data_2[3].data.x || []
        this.ydata2 = (this.chart_data_2[3].data.y || []).map((n)=>{
          return Math.floor((n /100000000) * 100) / 100
        })
        this.loadChart2();
      }, 22000);
      setTimeout( ()=> {
        this.timeChangeOut = '14:00'
        this.xdata2 = this.chart_data_2[4].data.x || []
        this.ydata2 = (this.chart_data_2[4].data.y || []).map((n)=>{
          return Math.floor((n /100000000) * 100) / 100
        })
        this.loadChart2();
      }, 24000);
      setTimeout( ()=> {
        this.timeChangeOut = '15:00'
        this.xdata2 = this.chart_data_2[5].data.x || []
        this.ydata2 = (this.chart_data_2[5].data.y || []).map((n)=>{
          return Math.floor((n /100000000) * 100) / 100
        })
        this.loadChart2();
      }, 26000)
      setTimeout( ()=> {
        $("#card3").addClass("animate__fadeOut");
      }, 28000);
      setTimeout(() => {
        $("#card3").addClass("none");
        $("#card4").removeClass("none");
        this.$refs.endVideo.play()
      }, 29000);
      // 总共34秒
      if(this.ip){
        setTimeout( ()=> {
          this.endRecord()
        }, 34000); 
      }
    },
  },
};
</script>
<style lang="less" scoped>
@import '@/assets/css/common.less';
  .container2{
    width: 100%;
  }
  .card{
    font-size: 14px*@beishu;
  }
  .logo-box{
    position: absolute;
    width: 210px*@beishu;
    bottom: 3%;
    left: 50%;
    transform: translateX(-50%);
    margin: auto;
  }
  .logo-box3{
    bottom: 5%;
  }
  .container {
    width: 100%;
    height: 95%;
    border: 1px cadetblue;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  .card_header{
      height: 60px*@beishu;
      line-height: 60px*@beishu;
      padding: 0 18px;
      background-color: #172A53;
      color: #AFF7FF;
  }
  .card_header span:first-of-type{
      font-size: 22px*@beishu;
      /* border-right: 2px solid #ccc;
      padding-right: 10px; */
  }
  .card_header span:last-of-type{
      float: right;
  }
  .card_box_title2{
    position: absolute;
    left: 50%;
    top: 40%;
    transform:translate(-50%,-50%);
    width: 250px*@beishu;
    height: 230px*@beishuHeight;
    border: 1px solid #85DEFF;
    text-align: center;
  }
  .card_box_title2 p:first-of-type{
    font-size: 36px*@beishu;
  }
  .card_box_title2 p:nth-of-type(2){
    font-size: 30px*@beishu;
  }
  .card_box_title2 p{
    margin: 16px 0;
  }
  .card_box_title2 p:nth-of-type(3){
    font-size: 18px*@beishu;
    margin-top: 26px;
  }
</style>